import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import Tools from 'components/Tools';

export default function ToolsPage(props) {
  let {
    data: { allContentfulTool },
  } = props;

  return (
    <Layout>
      <Tools tools={allContentfulTool.nodes} />
    </Layout>
  );
}

export const query = graphql`
  query {
    allContentfulTool {
      nodes {
        ...ContentfulToolFields
      }
    }
  }
`;
