import React from 'react';
import get from 'lodash.get';

import {
  Spacing,
  HeroSection,
  Slider,
  SliderTools,
  SharedStyles,
  Container,
  ListOfImg,
} from '@matchbox-mobile/kenra-storybook';

const { StSliderTools } = SliderTools;
const { StSectionTitle, StLink } = SharedStyles;

const COLOR_MANUAL_TYPE = 'Color manual';
const APPLICATION_CARD_TYPE = 'Application card';

export default function Tools({ tools }) {
  let manuals = tools.filter(tool => tool.type === COLOR_MANUAL_TYPE);
  let cards = tools.filter(tool => tool.type === APPLICATION_CARD_TYPE);
  return (
    <>
      <Spacing removeSpaceTop removeSpaceBottom>
        <HeroSection
          title="Stylist Tools"
          bgImg={`/images/salon-material.png`}
        />
      </Spacing>

      <Spacing>
        <StSectionTitle>
          <Container>
            <h2>Color Manuals</h2>
          </Container>
        </StSectionTitle>

        <Slider settings={{ slidesPerView: 'auto' }}>
          {manuals.map((manual, index) => (
            <StSliderTools key={index}>
              <img
                src={get(manual, 'image.localFile.square.resize.src')}
                alt=""
              />
              <h3>{manual.title}</h3>
              <StLink>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={get(manual, 'file.localFile.publicURL')}
                >
                  Download
                </a>
              </StLink>
            </StSliderTools>
          ))}
        </Slider>
      </Spacing>

      <Spacing>
        <section>
          <StSectionTitle>
            <Container>
              <h2>Application Cards</h2>
            </Container>
          </StSectionTitle>
          <ListOfImg
            // btnMoreHref="/"
            items={[
              ...cards.map(card => ({
                title: card.title,
                img: get(card, 'image.localFile.publicURL'),
                styleWidth50: !!card.wide,
                href: `/application/${card.title}`,
              })),
            ]}
          />
        </section>
      </Spacing>
    </>
  );
}
